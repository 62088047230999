<template>
  <div class="account-container">
    <div class="fixed-header">
      <!-- 顶部统计卡片 -->
      <Row :gutter="16" class="summary-cards">
        <Col span="8">
          <Card>
            <div class="summary-item">
              <Icon type="md-trending-down" size="28" color="#ff4d4f"/>
              <div class="summary-content">
                <p>本月支出</p>
                <h3>¥ {{ costMoney }}</h3>
              </div>
            </div>
          </Card>
        </Col>
        <Col span="8">
          <Card>
            <div class="summary-item">
              <Icon type="md-trending-up" size="28" color="#52c41a"/>
              <div class="summary-content">
                <p>本月收入</p>
                <h3>¥ {{ wageMoney }}</h3>
              </div>
            </div>
          </Card>
        </Col>
        <Col span="8">
          <Card>
            <div class="summary-item">
              <Icon type="md-wallet" size="28" color="#1890ff"/>
              <div class="summary-content">
                <p>结余</p>
                <h3>¥ {{  budget }}</h3>
              </div>
            </div>
          </Card>
        </Col>
      </Row>

      <!-- 操作栏 -->
      <div class="operation-bar">
        <Button type="primary" icon="md-add" @click="showAddModal" class="add-button">
          新增账单
        </Button>
        <Button type="default" icon="md-settings" @click="openBudgetModal" class="budget-button">
          设置预算
        </Button>
        <Button type="default" icon="md-download" @click="exportAccounts" class="export-button">
          导出账单
        </Button>
        <Button type="default" icon="md-upload" @click="importAccounts" class="import-button">
          导入账单
        </Button>
        <div class="filters">
          <DatePicker
            type="month"
            placeholder="选择月份"
            style="width: 200px"
            @on-change="handleDateChange"
            :clearable="false"
            v-model="selectedMonth"
          >
            <Icon type="md-calendar" slot="prefix" size="16"/>
          </DatePicker>
          <Select v-model="statusFilter" placeholder="选择状态" style="width: 120px">
            <Option value="">全部</Option>
            <Option value="0">支出</Option>
            <Option value="1">收入</Option>
          </Select>
        </div>
        <Input placeholder="搜索备注..." v-model="searchQuery" @on-enter="fetchAccounts" style="width: 200px">
          <Icon slot="prefix" type="md-search"/>
        </Input>
      </div>
    </div>

    <!-- 预算模态框 -->
    <Modal 
      v-model="showBudgetModal" 
      title="设置预算"
      @on-ok="handleBudgetSave"
      class="custom-modal"
    >
      <Form ref="budgetForm" :model="budgetData" :rules="budgetRules" :label-width="80">
        <FormItem label="预算金额" prop="budget">
          <InputNumber 
            v-model="budgetData.budget" 
            :min="0.01" 
            :step="0.01" 
            style="width: 200px"
            prefix="¥"
          />
        </FormItem>
      </Form>
    </Modal>

    <!-- 账单列表，标题固定，内容可滚动 -->
    <Card class="bill-list">
      <div class="bill-header" slot="title">
        <Icon type="md-list-box" size="20"/>
        <span style="margin-left: 8px">账单明细</span>
      </div>
      <div class="scrollable-table-content">
        <Table :columns="columns" :data="filteredAccounts" :loading="loading" stripe>
          <template slot-scope="{ row }" slot="money">
            <span :class="['amount-badge', row.statusPay === 1 ? 'income' : 'expense']">
              {{ row.statusPay === 1 ? '+' : '-' }}{{ row.money }}
            </span>
          </template>
          <template slot-scope="{ row }" slot="action">
            <div class="action-buttons">
              <Button 
                type="primary" 
                ghost
                size="small" 
                class="action-btn edit-btn"
                @click="handleEdit(row)"
              >
                <Icon type="md-create" size="14"/>
                编辑
              </Button>
              <Button 
                type="error" 
                ghost
                size="small" 
                class="action-btn delete-btn"
                @click="handleDelete(row)"
              >
                <Icon type="md-trash" size="14"/>
                删除
              </Button>
            </div>
          </template>
        </Table>
      </div>
    </Card>

    <!-- 添加/编辑模态框 -->
    <Modal 
      v-model="showModal" 
      :title="currentAccount ? '编辑账单' : '新增账单'"
      @on-ok="handleSave"
      class="custom-modal"
    >
      <div class="modal-header" slot="header">
        <Icon :type="currentAccount ? 'md-create' : 'md-add'" size="20"/>
        <span>{{ currentAccount ? '编辑账单' : '新增账单' }}</span>
      </div>
      <Form ref="accountForm" :model="formData" :rules="formRules" :label-width="80">
        <FormItem label="类型" prop="statusPay">
          <RadioGroup v-model="formData.statusPay">
            <Radio :label="0">
              <Icon type="md-arrow-dropdown" color="#ff4d4f"/>
              支出
            </Radio>
            <Radio :label="1">
              <Icon type="md-arrow-dropup" color="#52c41a"/>
              收入
            </Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="金额" prop="money">
          <InputNumber 
            v-model="formData.money" 
            :min="0.01" 
            :step="0.01" 
            style="width: 200px"
            prefix="¥"
          />
        </FormItem>
        <FormItem label="分类" prop="typeStatu">
          <div v-if="formData.statusPay === 0">
            <Tabs v-model="currentCategory">
              <TabPane label="食品饮料" name="0">
                <div class="category-grid">
                  <div v-for="item in categories.food" :key="item.id" 
                       @click="selectCategory(item)"
                       :class="{'selected': formData.typeStatu === item.id}">
                    <Icon :type="item.icon" size="20"/>
                    <span>{{item.name}}</span>
                  </div>
                </div>
              </TabPane>
              <TabPane label="衣裤鞋帽" name="1">
                <div class="category-grid">
                  <div v-for="item in categories.clothing" :key="item.id"
                       @click="selectCategory(item)"
                       :class="{'selected': formData.typeStatu === item.id}">
                    <Icon :type="item.icon" size="20"/>
                    <span>{{item.name}}</span>
                  </div>
                </div>
              </TabPane>
              <TabPane label="居家生活" name="2">
                <div class="category-grid">
                  <div v-for="item in categories.living" :key="item.id"
                       @click="selectCategory(item)"
                       :class="{'selected': formData.typeStatu === item.id}">
                    <Icon :type="item.icon" size="20"/>
                    <span>{{item.name}}</span>
                  </div>
                </div>
              </TabPane>
              <TabPane label="交通游玩" name="3">
                <div class="category-grid">
                  <div v-for="item in categories.transport" :key="item.id"
                       @click="selectCategory(item)"
                       :class="{'selected': formData.typeStatu === item.id}">
                    <Icon :type="item.icon" size="20"/>
                    <span>{{item.name}}</span>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
          
          <div v-else>
            <div class="category-grid">
              <div v-for="item in categories.income" :key="item.id"
                   @click="selectCategory(item)"
                   :class="{'selected': formData.typeStatu === item.id}">
                <Icon :type="item.icon" size="20"/>
                <span>{{item.name}}</span>
              </div>
            </div>
          </div>
        </FormItem>
        <FormItem label="日期" prop="day">
          <DatePicker 
            type="date" 
            v-model="formData.day" 
            style="width: 200px"
          >
            <Icon type="md-calendar" slot="prefix" size="16"/>
          </DatePicker>
        </FormItem>
        <FormItem label="备注" prop="remark">
          <Input 
            v-model="formData.remark" 
            type="textarea"
            :rows="4"
            placeholder="请输入备注信息..."
          />
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import { list, addAccount, modifyAccount, deleteAccount, setBudget, exportAccount } from '@/api/account' // 假设你有一个API方法来获取数据和添加账单

export default {
  name: 'AccountManagement',
  data() {
    return {
      loading: false,
      showModal: false,
      currentAccount: null,
      accounts: [],
      selectedMonth: new Date().toISOString().substr(0, 7), // 默认选中当前月份
      statusFilter: '', // 状态筛选，默认显示全部
      budget: 0,
      costMoney: 0,
      wageMoney: 0,
      columns: [
        {
          title: '日期',
          key: 'day',
          width: 120,
          render: (h, params) => {
            const date = new Date(params.row.day);
            return h('span', `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`);
          }
        },
        {
          title: '时间',
          key: 'recordtime',
          width: 100,
          render: (h, params) => {
            return h('span', params.row.recordtime);
          }
        },
        {
          title: '类型',
          key: 'name',
          width: 120,
          render: (h, params) => {
            const category = this.findCategory(params.row.typeStatu);
            return h('div', [
              h('Icon', {
                props: {
                  type: category ? category.icon : 'md-pricetag',
                  size: '14'
                },
                style: {
                  marginRight: '4px'
                }
              }),
              h('span', category ? category.name : '未知分类')
            ]);
          }
        },
        {
          title: '金额',
          slot: 'money',
          width: 120
        },
        {
          title: '备注',
          key: 'remark',
          ellipsis: true,
          tooltip: true
        },
        {
          title: '操作',
          slot: 'action',
          width: 150,
          align: 'center'
        }
      ],
      formData: {
        statusPay: 0,
        money: null,
        typeStatu: null,
        day: '',
        remark: ''
      },
      formRules: {
        statusPay: [{ required: true, message: '请选择收支类型' }],
        money: [{ required: true, message: '请输入金额' }],
        typeStatu: [{ required: true, message: '请选择分类' }],
        day: [{ required: true, message: '请选择日期' }]
      },
      currentCategory: '0',
      categories: {
        food: [
          { id: 1, name: '早中晚餐', icon: 'md-restaurant' },
          { id: 2, name: '饮料', icon: 'md-beer' },
          { id: 3, name: '蔬菜', icon: 'md-leaf' },
          { id: 4, name: '零食', icon: 'md-ice-cream' },
          { id: 5, name: '其他', icon: 'md-more' }
        ],
        clothing: [
          { id: 6, name: '衣服', icon: 'md-shirt' },
          { id: 7, name: '鞋子', icon: 'md-walk' },
          { id: 8, name: '帽子', icon: 'md-happy' },
          { id: 9, name: '首饰', icon: 'md-diamond' },
          { id: 10, name: '其他', icon: 'md-more' }
        ],
        living: [
          { id: 11, name: '日常用品', icon: 'md-home' },
          { id: 12, name: '厨房用品', icon: 'md-restaurant' },
          { id: 13, name: '床上用品', icon: 'md-bed' },
          { id: 14, name: '电器用品', icon: 'md-tv' },
          { id: 15, name: '其他', icon: 'md-more' }
        ],
        transport: [
          { id: 16, name: '交通费', icon: 'md-bus' },
          { id: 17, name: '油费', icon: 'md-car' },
          { id: 18, name: '娱乐', icon: 'md-game-controller-b' },
          { id: 19, name: '其他', icon: 'md-more' }
        ],
        income: [
          { id: 20, name: '工资', icon: 'md-card' },
          { id: 21, name: '兼职', icon: 'md-briefcase' },
          { id: 22, name: '福利', icon: 'md-gift' },
          { id: 23, name: '其他', icon: 'md-more' }
        ]
      },
      showBudgetModal: false,
      budgetData: {
        budget: null
      },
      budgetRules: {
        budget: [{ required: true, message: '请输入预算金额' }]
      },
      searchQuery: '', // 添加搜索查询字符串
    }
  },
  computed: {
    filteredAccounts() {
      // 如果没有搜索关键词，则返回所有账单
      if (!this.searchQuery) {
        return this.accounts;
      }
      // 根据备注信息进行搜索
      return this.accounts.filter(account => account.remark.includes(this.searchQuery));
    }
  },
  methods: {
    showAddModal() {
      this.adjustDatePickerZIndex(true);
      this.currentAccount = null;
      this.formData = {
        statusPay: 0,
        money: null,
        typeStatu: null,
        day: '',
        remark: ''
      };
      this.showModal = true;
    },
    closeModal() {
      this.adjustDatePickerZIndex(false);
      this.showModal = false;
    },
    adjustDatePickerZIndex(lower) {
      const zIndex = lower ? 100 : 1050;
      document.querySelectorAll('.ivu-date-picker-panel').forEach(panel => {
        panel.style.zIndex = zIndex;
      });
    },
    handleEdit(row) {
      this.currentAccount = row;
      this.formData = { 
        ...row,
        day: new Date(row.day) // 确保日期是 Date 对象
      };
      this.showModal = true;
    },
    handleDelete(row) {
      this.$Modal.confirm({
        title: '确认删除',
        content: '确定要删除这条账单记录吗？',
        onOk: () => {
          deleteAccount({ accountId: row.accountId }).then(response => {
            if (response.code === 0) {
              const index = this.accounts.findIndex(item => item.accountId === row.accountId);
              if (index > -1) {
                this.accounts.splice(index, 1);
                this.$Message.success('删除成功');
              }
            } else {
              this.$Message.error('删除失败');
            }
          }).catch(error => {
            this.$Message.error('删除失败');
            console.error(error);
          });
        }
      });
    },
    handleSave() {
      this.$refs.accountForm.validate(valid => {
        if (valid) {
          const date = new Date(this.formData.day);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          const formattedDate = `${year}-${month}-${day}`; // 格式化为 YYYY-MM-DD
          
          const accountData = {
            accountId: this.currentAccount ? this.currentAccount.accountId : undefined, // 添加 accountId
            statusPay: this.formData.statusPay,
            money: this.formData.money,
            typeStatu: this.formData.typeStatu,
            day: formattedDate,
            remark: this.formData.remark
          };

          const apiCall = this.currentAccount ? modifyAccount : addAccount; // 根据是否有 currentAccount 决定调用哪个 API

          apiCall(accountData)
            .then(response => {
              if (response.code === 0) {
                this.fetchAccounts();
                this.showModal = false;
                this.$Message.success(this.currentAccount ? '账单修改成功' : '账单添加成功');
              } else {
                this.$Message.error(response.msg || (this.currentAccount ? '账单修改失败' : '账单添加失败'));
              }
            })
            .catch(error => {
              this.$Message.error(this.currentAccount ? '账单修改失败' : '账单添加失败');
              console.error(error);
            });
        }
      });
    },
    fetchAccounts() {
      const [year, month] = this.selectedMonth.split('-');
      list({ year, month, search: this.searchQuery }) // 添加搜索参数
        .then(response => {
          if (response.code === 0) {
            const data = response.retVal;
            this.budget = data.budget;
            this.costMoney = data.costMoney;
            this.wageMoney = data.wageMoney;
            this.accounts = data.accountDetails.flatMap(detail => detail.accountValueList);
          } else {
            this.$Message.error(response.msg || '数据加载失败');
          }
        })
        .catch(error => {
          this.$Message.error('数据加载失败');
          console.error(error);
        });
    },
    selectCategory(item) {
      this.formData.typeStatu = item.id;
    },
    findCategory(typeStatu) {
      // 遍历所有分类查找匹配的项
      const allCategories = [
        ...this.categories.food,
        ...this.categories.clothing,
        ...this.categories.living,
        ...this.categories.transport,
        ...this.categories.income
      ];
      return allCategories.find(item => item.id === typeStatu);
    },
    openBudgetModal() {
      this.showBudgetModal = true;
    },
    handleBudgetSave() {
      this.$refs.budgetForm.validate(valid => {
        if (valid) {
          setBudget({ budget: this.budgetData.budget }).then(res => {
            if (res.code === 0) {
              this.$Message.success('预算设置成功');
              this.showBudgetModal = false;
            } else {
              this.$Message.error(res.msg || '预算设置失败');
            }
          })
          this.fetchAccounts();
          this.showBudgetModal = false;
        }
      });
    },
    exportAccounts() {
      const [year, month] = this.selectedMonth.split('-');
      exportAccount({ year, month })
        .then(response => {
          if (response.code === 0) {
            console.log(response.msg);
            this.$Message.success(response.retVal);
          } else {
            this.$Message.error(response.msg || '账单导出失败');
          }
        })
        .catch(error => {
          this.$Message.error('账单导出失败');
          console.error(error);
        });
    },
    importAccounts() {
      // 导入账单逻辑
      this.$Message.success('暂未支持');
    },
    handleDateChange(date) {
      this.selectedMonth = date;
      this.fetchAccounts(); // 更新月份后重新获取数据
    }
  },
  mounted() {
    this.fetchAccounts(); // 页面加载时请求当前月份的数据
  }
}
</script>

<style scoped>
.account-container {
  display: flex;
  flex-direction: column;
  height: 90vh; /* 视口高度，确保整个组件填满屏幕 */
  overflow: hidden; /* 防止容器本身滚动 */
}

.fixed-header {
  background: #f0f2f5;
  padding: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.bill-list {
  flex-grow: 1; /* 占据剩余空间 */
  display: flex;
  flex-direction: column;
  overflow: hidden; /* 防止整个卡片滚动 */
}

.scrollable-table-content {
  overflow-y: auto; /* 允许在垂直方向上滚动 */
  flex-grow: 1; /* 占据剩余空间，确保表格内容可滚动 */
  height: calc(100vh - 250px); /* 计算高度，减去固定头部和其他固定内容的高度 */
}

.summary-cards {
  position: sticky; /* 设置为sticky，使其在滚动时固定在顶部 */
  top: 0; /* 顶部对齐 */
  z-index: 10; /* 确保它位于其他内容之上 */
  background: #f0f2f5; /* 设置背景色以覆盖滚动内容 */
  padding-bottom: 12px; /* 添加一些底部间距 */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* 添加阴影以增强视觉分隔效果 */
  margin-bottom: 12px; /* 保持原有的底部间距 */
}

.operation-bar, .bill-list, .pagination-container {
  margin-bottom: 12px;
}

.summary-item {
  display: flex;
  align-items: center;
  padding: 12px;
  transition: all 0.3s;
}

.summary-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}

.summary-content {
  margin-left: 16px;
}

.summary-content p {
  margin: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.summary-content h3 {
  margin: 4px 0 0;
  font-size: 24px;
  font-weight: 600;
}

.operation-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
}

.add-button {
  padding: 6px 16px;
  height: 36px;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: all 0.3s;
}

.add-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(24, 144, 255, 0.3);
}

.filters {
  display: flex;
  gap: 16px;
}

.bill-header {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
  font-size: 16px;
  font-weight: 500;
}

.income {
  color: #52c41a;
  font-weight: 600;
  font-size: 14px;
}

.expense {
  color: #ff4d4f;
  font-weight: 600;
  font-size: 14px;
}

.custom-modal :deep(.ivu-modal) {
  max-height: 100%;
  overflow: auto;
  z-index: 2000;
}

.custom-modal :deep(.ivu-modal-header) {
  padding: 16px;
  background: #f8f9fa;
}

.custom-modal :deep(.ivu-modal-body) {
  padding: 16px;
}

.modal-header {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
}

/* 添加一些动画效果 */
:deep(.ivu-card) {
  transition: all 0.3s;
}

:deep(.ivu-card:hover) {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}

:deep(.ivu-table-row) {
  transition: all 0.3s;
}

:deep(.ivu-table-row:hover) {
  background-color: #f5f5f5;
}

:deep(.ivu-form-item) {
  margin-bottom: 24px;
}

:deep(.ivu-input-wrapper:hover),
:deep(.ivu-select-selection:hover),
:deep(.ivu-input-number-wrapper:hover) {
  border-color: #1890ff;
}

:deep(.ivu-btn) {
  transition: all 0.3s;
}

:deep(.ivu-table) {
  margin-top: 16px;
}

:deep(.ivu-table-stripe .ivu-table-body tr:nth-child(2n) td) {
  background-color: #fafafa;
}

.category-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  padding: 16px;
}

.category-grid > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.category-grid > div:hover {
  background: #f5f5f5;
}

.category-grid > div.selected {
  background: #e6f7ff;
  color: #1890ff;
}

.action-buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 12px;
  transition: all 0.3s;
  border-radius: 4px;
}

.action-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.edit-btn {
  border-color: #2d8cf0;
  color: #2d8cf0;
}

.edit-btn:hover {
  background: #2d8cf0;
  color: #fff;
  border-color: #2d8cf0;
}

.delete-btn {
  border-color: #ed4014;
  color: #ed4014;
}

.delete-btn:hover {
  background: #ed4014;
  color: #fff;
  border-color: #ed4014;
}

/* 添加按钮图标的动画效果 */
.action-btn .ivu-icon {
  transition: all 0.3s;
}

.edit-btn:hover .ivu-icon {
  transform: rotate(360deg);
}

.delete-btn:hover .ivu-icon {
  animation: shake 0.5s;
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-2px); }
  75% { transform: translateX(2px); }
}

/* 更新分页相关样式 */
.pagination-container {
  margin-top: 24px;
  padding: 0 16px;
}

.pagination-wrapper {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.98));
  border-radius: 12px;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.8);
}

.pagination-info {
  display: flex;
  align-items: center;
}

.total-text {
  color: #666;
  font-size: 14px;
  font-weight: 500;
}

/* 美化分页器组件 */
:deep(.ivu-page) {
  display: flex;
  align-items: center;
  gap: 8px;
}

:deep(.ivu-page-item) {
  border: 1px solid #e8eaec;
  background: linear-gradient(to bottom, #ffffff, #fafafa);
  border-radius: 8px;
  min-width: 36px;
  height: 36px;
  line-height: 34px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

:deep(.ivu-page-item:hover) {
  border-color: #1890ff;
  color: #1890ff;
  transform: translateY(-1px);
  box-shadow: 0 2px 12px rgba(24, 144, 255, 0.1);
}

:deep(.ivu-page-item-active) {
  background: linear-gradient(135deg, #1890ff, #36cfc9);
  border-color: transparent;
  color: white;
}

:deep(.ivu-page-item-active:hover) {
  color: white;
}

:deep(.ivu-page-options) {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: 16px;
}

:deep(.ivu-page-options-elevator) {
  display: flex;
  align-items: center;
  gap: 8px;
}

:deep(.ivu-page-options-elevator input) {
  width: 60px;
  height: 36px;
  border-radius: 8px;
  border: 1px solid #e8eaec;
  text-align: center;
  transition: all 0.3s;
}

:deep(.ivu-page-options-elevator input:hover),
:deep(.ivu-page-options-elevator input:focus) {
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
}

:deep(.ivu-select-selection) {
  border-radius: 8px;
  height: 36px;
  line-height: 34px;
  background: linear-gradient(to bottom, #ffffff, #fafafa);
}

:deep(.ivu-page-prev),
:deep(.ivu-page-next) {
  background: linear-gradient(to bottom, #ffffff, #fafafa);
  border-radius: 8px;
  min-width: 36px;
  height: 36px;
  line-height: 34px;
  transition: all 0.3s;
}

:deep(.ivu-page-prev:hover),
:deep(.ivu-page-next:hover) {
  border-color: #1890ff;
  color: #1890ff;
  transform: translateY(-1px);
  box-shadow: 0 2px 12px rgba(24, 144, 255, 0.1);
}

:deep(.ivu-select-dropdown) {
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
}

:deep(.ivu-select-item) {
  padding: 8px 16px;
  transition: all 0.3s;
}

:deep(.ivu-select-item:hover) {
  background: rgba(24, 144, 255, 0.1);
}

:deep(.ivu-select-item-selected) {
  background: linear-gradient(135deg, #1890ff, #36cfc9);
  color: white;
}

:deep(.ivu-select-item-selected:hover) {
  background: linear-gradient(135deg, #1890ff, #36cfc9);
  color: white;
}

.custom-modal :deep(.ivu-form-item) {
  margin-bottom: 12px;
}

.custom-modal :deep(.ivu-input),
.custom-modal :deep(.ivu-select),
.custom-modal :deep(.ivu-date-picker) {
  height: 32px;
}

.custom-modal :deep(.form-row) {
  display: flex;
  justify-content: space-between;
}

.custom-modal :deep(.form-row) .ivu-form-item {
  flex: 1;
  margin-right: 8px;
}

.custom-modal :deep(.form-row) .ivu-form-item:last-child {
  margin-right: 0;
}

.budget-button, .export-button, .import-button {
  margin-left: 8px;
}

:deep(.ivu-date-picker) {
  z-index: 1050; /* 仅对模态框中的日期选择器生效 */
}

.custom-modal :deep(.ivu-modal-body) {
  max-height: 80vh; /* 限制模态框高度，避免内容溢出 */
  overflow-y: auto;
}

.custom-modal :deep(.ivu-date-picker-rel) {
  z-index: 1050; /* 仅对模态框中的日期选择器生效 */
}

#mainDatePicker .ivu-date-picker-panel {
  z-index: 1050;  /* 默认层级 */
}

#mainDatePicker[disabled] .ivu-input,
#mainDatePicker[disabled] .ivu-icon {
  color: rgba(0, 0, 0, 0.25); /* 调整禁用时的图标和文字颜色 */
  cursor: not-allowed;
}

#mainDatePicker[disabled] .ivu-input {
  background-color: #f5f5f5; /* 调整禁用时的背景色 */
  border-color: #d9d9d9; /* 调整禁用时的边框颜色 */
}

/* 增加整体字体的现代感和可读性 */
body, html {
  font-family: 'Roboto', sans-serif; /* 使用 Google Fonts 的 Roboto 字体 */
  color: #333; /* 深灰色字体，减少视觉疲劳 */
}

/* 优化按钮的交互效果 */
.button {
  background-color: #1890ff; /* 蓝色按钮 */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.2s;
}

.button:hover {
  background-color: #40a9ff; /* 按钮悬停时的颜色 */
  transform: translateY(-2px); /* 轻微上移，增加动态效果 */
}

/* 输入框样式 */
.input {
  border: 1px solid #ccc;
  padding: 8px 12px;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.input:focus {
  border-color: #1890ff; /* 聚焦时边框颜色 */
  box-shadow: 0 0 8px rgba(24, 144, 255, 0.2); /* 添加聚焦时的阴影效果 */
}

/* 卡片阴影和圆角 */
.card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .card {
    margin: 10px;
  }
}
</style> 