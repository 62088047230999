import request from '@/utils/request'

export function list(params) {
    return request.get('/diary/account/list', { params })
}
export function addAccount(params) {
    return request.post('/diary/account/save', params)
}
export function modifyAccount(params) {
    return request.post('/diary/account/modify', params)
}
export function deleteAccount(params) {
    return request.get('/diary/account/delete', { params })
}
export function setBudget(params) {
    return request.post('/diary/accountBudget/setBudget', params)
}
export function exportAccount(params) {
    return request.get('/diary/account/export', { params })
}

export function importAccount(file) {
    // 创建 FormData 对象
    const formData = new FormData();
    formData.append('file', file);  // 'file' 是后端接收的参数名
  
    // 发送 POST 请求，传递文件
    return request.post('/diary/account/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // 设置请求头为 multipart/form-data
      },
    });
  }
